<template>
  <div>
    <header class="dfc">
      <h2 class="mr20">设备信息</h2>
      <el-button class="bg3 " @click="goBack">
        <i class="el-icon-check iback"></i>
        返回列表
      </el-button>
      <el-button class=" bg8" @click="freshBtn">
        <i class="el-icon-check ifresh"></i>
        刷新
      </el-button>
      <el-button class="bg7" @click="gotoCardMessage">物联卡信息</el-button>
      <el-button class="bg6" @click="mileageBtn">里程统计</el-button>
      <el-button class="bg1" @click="bmsHistoryBtn">BMS历史数据</el-button>
    </header>
    <!-- 基本信息 -->
    <h3 class="mt20 bgf">基本信息</h3>
    <div class="info-wrap">
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>设备编号</p>
        </div>
        <p class="tac" >{{ info.haiLiDeviceNo || ''}}</p>
      </div>

      <!--  -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池二维码</p>
        </div>
        <p class="tac" >{{ info.linkNo}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>通讯板编号</p>
        </div>
        <p >{{ info.deviceId || ''}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>产品型号</p>
        </div>
        <p >{{ info.productName || ''}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池电压</p>
        </div>
        <p >{{ info.electricVoltage || '0V'}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池电量</p>
        </div>
        <p >{{ info.soc || '0%'}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池电流</p>
        </div>
        <p >{{ info.electricCurrent || '0A'}}</p>
      </div>
      <!--  -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池串数</p>
        </div>
        <p >{{ info.cellQuantity || 0}}串</p>
      </div>
      <!--  -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>充电保护</p>
        </div>
        <p class="tac" >
          {{ $changeObj(DISCHARGE_STATUS)[info.chargeProtectStauts] }}
        </p>
      </div>
      <!--  -->

      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>放电保护</p>
        </div>
        <p class="tac" >
          {{ $changeObj(DISCHARGE_STATUS)[info.dischargeProtectStatus] }}
        </p>
      </div>
      <!--  -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>校准容量</p>
        </div>
        <p >{{ info.calibrationCapacity || "0AH"}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>放电总容量</p>
        </div>
        <p >{{ info.dischargeTotalQuantity || '0AH'}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>工作状态</p>
        </div>
        <p >{{ $changeObj(WORK_STATUS)[info.workStatus] }}</p>
      </div>
      <!-- <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>温度状态</p>
        </div>
        <p>{{ info.temperatureStatus }}V</p>
      </div> -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>功率板温度</p>
        </div>
        <p class="tac" >{{ info.powerBoardTemperature || '0℃'}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池超温</p>
        </div>
        <p class="tac" >{{$changeObj(TEMPERATURE_STATUS)[info.temperatureStatus]}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>循环次数</p>
        </div>
        <p >{{ info.cycleIndex || 0}}</p>
      </div>
      <!--  -->
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>设备激活时间</p>
        </div>
        <p class="tac" v-if="info.activationTime">{{ info.activationTime | timeToDate}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>电池出厂时间</p>
        </div>
        <p class="tac" v-if="info.comeOnTime">{{ info.comeOnTime | timeToDate}}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p class="tac">BMS数据更新时间</p>
        </div>
        <p class="tac" v-if="info.bmsUpdateTime">{{ info.bmsUpdateTime | timeToDate }}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p class="tac">BMS供应商</p>
        </div>
        <p class="tac" v-if="info.bmsSupplierName">{{ info.bmsSupplierName }}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p class="tac">测试人员</p>
        </div>
        <p class="tac" v-if="info.testers">{{ info.testers }}</p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>发布人员</p>
        </div>
        <p class="tac" v-if="info.publisher">{{ info.publisher }}</p>
      </div>
    </div>

    <!-- 报警状态 -->
    <h3 class="bgf">报警状态</h3>
    <section class="info-wrap">
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>放电过流</p>
        </div>
        <p class="tac">
          {{$changeObj(ABNORMAL_STATUS)[alarmInfo.dischargeOverCurrent]}}
        </p>
      </div>

       <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>充电过流</p>
        </div>
        <p class="tac">
          {{$changeObj(ABNORMAL_STATUS)[alarmInfo.chargingOverCurrent]}}
        </p>
      </div>
       <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>MOS超温</p>
        </div>
        <p class="tac">
          {{$changeObj(ABNORMAL_STATUS)[alarmInfo.mosOverTemperature]}}
        </p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>低容量报警</p>
        </div>
        <p class="tac">
          {{$changeObj(ABNORMAL_STATUS)[alarmInfo.lowCapacity]}}
        </p>
      </div>
    </section>

    <!-- 设备开关 -->
    <h3 class="mt20 bgf">设备开关</h3>
    <div class="info-wrap dfa">
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>充电开关</p>
        </div>
        <p>
          <!-- <span>关闭</span>  -->
          <!-- v-model="switchInfo.chargeMosSwitch" -->

          <el-switch
            v-model="value1"
            active-text="开启"
            @change="handleBattery"
            inactive-text="关闭"
          >
          </el-switch>
          <!-- <span class="check" @click="handleBattery(21)">切换状态</span> -->
        </p>
      </div>
      <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>放电开关</p>
        </div>
        <!-- v-model="switchInfo.dischargeMosSwitch" -->
        <el-switch
          v-model="value2"
          active-text="开启"
          @change="handleBattery2"
          inactive-text="关闭"
        >
        </el-switch>
      </div>
        <!-- v-model="switchInfo.balancedStatus" -->

      <!-- <div class="block">
        <div class="dfcc circleBox">
          <span class="circle"></span>
          <p>均衡开关</p>
        </div>
        <el-switch
          v-model="value3"
          active-text="开启"
          @change="handleBattery3"
          inactive-text="关闭"
        >
        </el-switch>
      </div> -->
    </div>

    <!-- 电池温度 -->
    <el-row :gutter="20" class="mb30  ">
      <el-col :span="12">
        <h3 class="bgf">电池温度</h3>
        <div class="section-wrapper">
          <lttEcharts
            refLabel="eleTemperature"
            :option="eleTemperature"
          ></lttEcharts>
        </div>
      </el-col>
      <el-col :span="12">
        <h3 class="bgf"> 电芯电压</h3>
        <div class="section-wrapper fd dfjb">
          <lttEcharts refLabel="elePressure" :option="elePressure"></lttEcharts>
          <ul class="elePressBox dfc">
            <li class="high">最高电压：{{hElePressure}}V</li>
            <li class="low">最低电压：{{sElePressure}}V</li>
            <li class="poor">相差电压：{{cut}}V</li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <!-- 地图 -->
    <el-row>
      <h3 class="mb30 bgf">定位信息</h3>
      <div class="batteryTrack" >
        <el-amap vid="batteryTrack" :zoom="zoom" :center="center" v-if="center.length"
          :plugin="plugin" >
            <!-- <el-amap-marker
              v-for="(marker, index) in markers"
              :position="marker.position"
              :visible="marker.visible"
              :draggable="marker.draggable"
              :vid="index"
              :label="marker.label"
              :events="marker.events"
              :icon="marker.icon"
              :offset="marker.offset"
              v-bind:key="index"
            >
          </el-amap-marker> -->
          <el-amap-marker
          :position="center">
          </el-amap-marker>
              <el-amap-info-window
              :position="center"
              :visible="window.visible"
              :content="window.content"
              :offset="window.offset"
            ></el-amap-info-window>

           <!-- <el-amap-circle vid="circle"
              :center="center"
              :radius="radius"
              fill-opacity="0.2"
              strokeColor="#38f"
              strokeOpacity="0.8"
              strokeWeight="1"
              fillColor="#38f"
              >
              </el-amap-circle> -->
        </el-amap>
      </div>
    </el-row>
  </div>
</template>

<script>
// 21：开启充电；22：关闭充电；23：开启放电；24：关闭放电
// import { AMapManager,lazyAMapApiLoaderInstance } from 'vue-amap';
import WGS from '../../js/WGS.js'
import {
  getBatteryDetail,
  handleBattery,
  getBatteryTrack,
  handleChargeBattery,
  handleDischargeBattery
} from "@/api/facility";
import { WORK_STATUS, CHARGE_STATUS, TEMPERATURE_STATUS ,DISCHARGE_STATUS,ABNORMAL_STATUS} from "@/js/constant";
import lttEcharts from "@/components/lttEcharts";
export default {
  data() {
    const self = this;
    return {
      formattedAddress:'',
      plugin: [
        {
          pName: 'Geocoder',
          events: {
            init(o) {
              //定位第一次逆解码
              o.getAddress(self.center, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  self.formattedAddress=result.regeocode.formattedAddress
                }

              })
            }
          }
        }
      ],
      ABNORMAL_STATUS,
      TEMPERATURE_STATUS,
      WORK_STATUS,
      CHARGE_STATUS,
      DISCHARGE_STATUS,
      alarmInfo:[],
      // 开关
      switchInfo: {
        balancedStatus:true,
        chargeMosSwitch:true,
        dischargeMosSwitch:true
      },
      // 基本信息数据
      info: {
        bmsSupplierName:'',
        haiLiDeviceNo:'',
        activationTime:'',
        bmsType:'',
        bmsUpdateTime:'',
        calibrationCapacity:'',
        cellQuantity:'',
        chargeProtectStauts:'',
        comeOnTime:'',
        cycleIndex:'',
        deviceId:'',
        dischargeProtectStatus:'',
        dischargeTotalQuantity:'',
        electricCurrent:'',
        electricVoltage:'',
        powerBoardTemperature:'',
        linkNo:'',
        productName:'',
        publisher:'',
        productNo:'',
        soc:'',
        temperatureStatus:'',
        testers:'',
        workStatus:'',
      },
      mapInfo:{
        locationTime:'0',
        locationType:'',
        signalTime:'0',
        nothing:''
      },
      //地图信息
      visible:false,//地图弹窗显示
      windows:[],
      window:{
        offset:[0,-30],
        visible:true,
        anchor:'bottom-right',
        content:''
      },
      radius:100,//圆圈范围大小
      label:{
        content:'标记',
        // offset:[10,12]
      },
      markers:[],
      zoom: 15,//缩放级别
      center:[117.526342,39.133823],
      trackPoint: {},
      selectIndex: null,

      // 电池温度echarts
      list: [],//charts温度
      eleTemperature: {
        color: ["rgb(130, 77, 214)"],
        xAxis: {
          type: "category",
          data:[],
          axisLabel:{
            rotate:60,
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            label: {
                show: true,
                position: 'inside'
            },
          }
        ]
      },
      // echarts电芯电压
      hElePressure:0,//最高电压
      sElePressure:0,//最低电压
      elePressure: {
        color: ["rgb(61, 243, 204)"],
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: "直接访问",
            type: "bar",
            label: {
                show: true,
                position: 'inside'
            },
            data: []
          }
        ]
      },
      detail: {
        info: {}
      },
      disabled: false,
      value1: false,
      value2: false,
      value3: false,

      dischargeProtectStatus: {
        0: "正常",
        1: "失效"
      },
      chargeProtectStauts: {
        0: "正常",
        1: "失效"
      },
      temperatureStatus: {
        0: "正常",
        1: "超温"
      },
      timer: null,
      num: 60,
      loading: false
    };
  },
  mounted() {
    this.getBatteryDetail();
  },
  computed:{
    cut(){
      return  (this.hElePressure - this.sElePressure).toFixed(3);
    }
  },
  methods: {
    // 高德地图获取文字位置
    getaddress: function(lnglat) {
				let self=this
				AMap.plugin('AMap.Geocoder', function() {
					var geocoder = new AMap.Geocoder({
						// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
						city: '010'
					})
					geocoder.getAddress(lnglat, function(status, result) {
						if (status === 'complete' && result.info === 'OK') {
							self.formattedAddress=result.regeocode.formattedAddress;
              self.contentHandle();
							// result为对应的地理位置详细信息
						}
					})
				})
			},
    // 返回列表
    goBack(){
       this.$router.push(this.$route.query.back);
    },
    // bms按钮
    bmsHistoryBtn() {
      this.$router.push({
        path: "/leaseholdMgmt/bmsHistory",
        query:{
          haiLiDeviceNo:this.$route.query.deviceNo,
        }
       });
    },
    // // 里程统计按钮
    mileageBtn() {
       this.$router.push({
         path: "/leaseholdMgmt/mileage",
         query:{
           haiLiDeviceNo:this.$route.query.deviceNo
         }
      });
    },
    // // 刷新按钮
    freshBtn() {
      this.getBatteryDetail();
    },
    // // 物联卡信息btn
    gotoCardMessage() {
      this.$router.push({
         path: "/leaseholdMgmt/cardMessage",
        query:{
          haiLiDeviceNo:this.$route.query.deviceNo
        }
      });
    },
    // },
    // // 充电开关
    // async handleBattery() {
    //   let operateType = "";
    //   if (this.value1) {
    //     operateType = 21;
    //   } else {
    //     operateType = 22;
    //   }
    //   const params = {
    //     haiLiDeviceNo: this.$route.query.deviceNo,
    //     operateType
    //   };
    //   if (this.disabled) {
    //     return;
    //   }
    //   this.disabled = true;
    //   try {
    //     await handleBattery(params);
    //     this.disabled = false;
    //     this.$message.success("操作成功");
    //     this.getBatteryDetail();
    //   } catch (error) {
    //     this.disabled = false;
    //     this.getBatteryDetail();
    //   }
    // },
    // // 放电开关
    // async handleBattery2() {
    //   let operateType = "";
    //   if (this.value2) {
    //     operateType = 23;
    //   } else {
    //     operateType = 24;
    //   }
    //   const params = {
    //     haiLiDeviceNo: this.$route.query.deviceNo,
    //     operateType
    //   };
    //   if (this.disabled) {
    //     return;
    //   }
    //   this.disabled = true;
    //   try {
    //     await handleBattery(params);
    //     this.disabled = false;
    //     this.$message.success("操作成功");
    //     this.getBatteryDetail();
    //   } catch (error) {
    //     this.disabled = false;
    //     this.getBatteryDetail();
    //   }
    // },
    // 均衡开关
    // },


// 获取数据
    async getBatteryDetail() {
      const params = {
        haiLiDeviceNo: this.$route.query.deviceNo
        // haiLiDeviceNo:'1616483580917626422'
      };
      if (this.loading) return;
      this.loading = true;
      try {
        const res = await getBatteryDetail(params);
        console.log(res,'res');
        this.detail = res.mdata.deviceDetail;
       // let electricVoltageInfo = res.mdata.deviceDetail.electricVoltageInfo;

        if (!this.timer) {//第一次请求数据
          this.value1 = this.detail.switchInfo.chargeMosSwitch == 1;
          this.value2 = this.detail.switchInfo.dischargeMosSwitch == 1;
          this.value3 = this.detail.switchInfo.balancedStatus == 1;
        }
        // 基本信息
        this.info = this.detail.info;
        // 开关
        //  this.switchInfo = this.detail.switchInfo;
        // 温度
        this.list = Object.values(this.detail.temperatureInfo);
        let temperatureXArr = ['均衡板温度','电池功率板温度'];
        for(let i = 1;i < this.list.length - 1;i ++){
          temperatureXArr.push(`电池${i}`);
        }
        this.eleTemperature.xAxis.data = temperatureXArr;//温度x轴
        this.eleTemperature.series[0].data = this.list;//y轴数据

        //电压
        let electricVoltageInfo = this.detail.electricVoltageInfo;
        let voltageInfoArr = Object.values(electricVoltageInfo);

        if(voltageInfoArr.length){
          let volXArr = [];
          for(let i = 0;i < voltageInfoArr.length;i ++){
            volXArr.push(i + 1);
          }
          this.elePressure.xAxis.data = volXArr;//X轴
          // 找出电压最大最小值
          let elePressureArr = [...voltageInfoArr];
          elePressureArr.sort((a,b)=>{
            return a - b;
          });
          const small = elePressureArr.shift();
          const big =  elePressureArr.pop();
          this.sElePressure = small;
          this.hElePressure = big;
          const i = voltageInfoArr.indexOf(small);
          const i2 = voltageInfoArr.indexOf(big);
          voltageInfoArr.splice(i,1,{value:small,itemStyle:{color:'red'}});
          voltageInfoArr.splice(i2,1,{value:big,itemStyle:{color:'rgb(130, 77, 214)'}});
          this.elePressure.series[0].data = voltageInfoArr;//Y轴数据
        }


        // 定位
        this.mapInfo = {...this.detail.locationInfo};
        // WGS-84 to GCJ-02定位转化
        if(this.detail.locationInfo.latitude && this.detail.locationInfo.longitude && this.mapInfo.deviceType == 5){
         const WGSObj = WGS.gcj_encrypt(+this.detail.locationInfo.latitude,+this.detail.locationInfo.longitude);
          const lat = WGSObj.lat;
          const lon = WGSObj.lon;
          this.$set(this.center,0,lon);
          this.$set(this.center,1,lat);
        }
        if(this.detail.locationInfo.latitude && this.detail.locationInfo.longitude && this.mapInfo.deviceType == 3){
          this.$set(this.center,0,+this.detail.locationInfo.longitude);
          this.$set(this.center,1,+this.detail.locationInfo.latitude);
        }
          this.getaddress(this.center);

        // 报警
        this.alarmInfo = res.mdata.deviceDetail.alarmInfo;

        // this.eleTemperature.
        // 电压charts数据,找出最大最小值，变色
        // let originArr = Object.values(this.detail.electricVoltageInfo);//必须是数字
        // let elePressureArr = [...originArr];
        // elePressureArr.sort((a,b)=>{
        //   return a - b;
        // });
        // const small = elePressureArr.shift();
        // const big =  elePressureArr.pop();
        // this.sElePressure = small;
        // this.hElePressure = big;
        // const i = originArr.indexOf(small);
        // const i2 = originArr.indexOf(big);
        // originArr.splice(i,1,i,{value:small,itemStyle:{color:'pink'}});
        // originArr.splice(i2,1,i2,{value:small,itemStyle:{color:'red'}});

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    // 生成地图弹窗
    contentHandle(){
          let locationAlready = this.detail.locationInfo.longitude ? '已定位' : '未定位';
          let location = this.mapInfo.location;
          let signalTime = this.mapInfo.signalTime;
          let locationTime = this.mapInfo.locationTime;
          signalTime = this.handleTime(signalTime);
          locationTime = this.handleTime(locationTime);
        this.window.content = `<div class="dialog">
        <div class="dialogBox">
          <p>定位类型：${locationAlready}</p>
          <p>详细地址：${this.formattedAddress || '暂无'}</p>
          <p>位置更新时间：${locationTime }</p>
          <p>信号更新时间：${signalTime }</p>
        </div>
      </div>`
    },
    // 处理时间
    handleTime(date){
       if(!date){
         return '无'
       }
        let locationTime = new Date(parseInt(date));
        let year = locationTime.getFullYear() + '';
        let mon = (locationTime.getMonth() + 1 + '').padStart(2,'0');
        let day = (locationTime.getDate() + '').padStart(2,'0');
        let hou = (locationTime.getHours() + '').padStart(2,'0');
        let min = (locationTime.getMinutes() + '').padStart(2,'0');
        const time = `${year}-${mon}-${day} ${hou}:${min}`;
        return time;
    },

    // 充电开关
    async handleBattery() {
      let operateType = "";
      if (this.value1) {
        operateType = 21;
      } else {
        operateType = 22;
      }
      const params = {
        haiLiDeviceNo: this.$route.query.deviceNo,
        // haiLiDeviceNo:'1616483580917626422',
        operateType
      };
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      try {
        await handleChargeBattery(params);
        this.disabled = false;
        this.$message.success("操作成功");
        if (this.timer) {
          return;
        }
        this.loopDetail();
      } catch (error) {
        this.disabled = false;
        this.getBatteryDetail();
      }
    },
    // 放电开关
    async handleBattery2() {
      let operateType = "";
      if (this.value2) {
        operateType = 23;
      } else {
        operateType = 24;
      }
      const params = {
        haiLiDeviceNo: this.$route.query.deviceNo,
        // haiLiDeviceNo:'1616483580917626422',

        operateType
      };
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      try {
        await handleDischargeBattery(params);
        this.disabled = false;
        this.$message.success("操作成功");
        if (this.timer) {
          return;
        }
        this.loopDetail();
      } catch (error) {
        this.disabled = false;
        this.getBatteryDetail();
      }
    },
    // 均衡开关
    async handleBattery3() {
      let operateType = "";
      if (this.value3) {
        // operateType = 23;
      } else {
        // operateType = 24;
      }
      const params = {
        haiLiDeviceNo: this.$route.query.deviceNo,
        // haiLiDeviceNo:'1616483580917626422',
        operateType
      };
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      try {
        await handleDischargeBattery(params);
        this.disabled = false;
        this.$message.success("操作成功");
        if (this.timer) {
          return;
        }
        this.loopDetail();
      } catch (error) {
        this.disabled = false;
        this.getBatteryDetail();
      }
    },
    // 60秒请求一次数据
    loopDetail() {
      this.timer = setTimeout(() => {
        this.num--;
        this.getBatteryDetail();
        this.loopDetail();
        if (this.num == 0) {
          clearTimeout(this.timer);
          this.getBatteryDetail();
          this.num = 60;
        }
      }, 1000);
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  components: {
    lttEcharts
  }
};
</script>

<style scoped lang="less">
.batteryTrack {
  width: 100%;
  height: 600px;
}
.info-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 15%;
    span {
      margin-right: 10px;
    }
    .circleBox {
      margin-bottom: 20px;
    }
    /* :second-child {
      margin-right: 10px;
    } */
  }
  .check {
    color: #409eff;
    margin-left: 20px;
  }
}
.el-button {
  color: #fff;
}
.el-button i {
  background: #fff;
  border-radius: 50%;
}
.el-button .iback {
  color: rgb(61, 243, 204);
}
.el-button .ifresh {
  color: rgb(251, 96, 34);
}
section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
section .newBox {
  margin-bottom: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
}
section .newBox p {
  margin: 10px 0;
}
.elePressBox {
  li {
    padding: 5px 50px;
    flex: 1 1 auto;
  }
  .high {
    border-left: 5px solid rgb(130, 77, 214);
  }
  .low {
    border-left: 5px solid rgb(255, 161, 37);
  }
  .poor {
    border-left: 5px solid red;
  }
}
.section-wrapper{
  height: 600px;
}


</style>
